import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
  button: {
    borderRadius: 10,
    background:
      "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
  },
  text: {
    color: "#ffffff",
    textShadow: "2px 2px #000000",
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
    fontWeight: "bold",
    "&:hover": {
      color: "#f50d57",
    },
  },
});
const LoginButton = () => {
  const styles = useStyles();
  return (
    <div>
      <Button
        component={RouterLink}
        to="/signin"
        size="large"
        className={styles.button}
        variant="contained"
      >
        <Typography className={styles.text}>Login</Typography>
      </Button>
    </div>
  );
};

export default LoginButton;
