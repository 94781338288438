import React, { useState } from "react";
import "../../src/assets/css/mocktable.css";
import { useDispatch, useSelector } from "react-redux";
import { createCustomer } from "../store/actions/actions";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "../../src/assets/css/collapse.css";
import ParentDialog from "./ParentAndChildMock/ParentDialog";
import ChildDialog from "./ParentAndChildMock/ChildDialog";
import { Icon } from "semantic-ui-react";
import { useSnackbar } from "notistack";

let config = require("../config/config");

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    marginBottom: "-10px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  parentButton: {
    background:
      "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
    padding: "1px 1px 1px 1px",
    color: "#fafafa",
    fontWeight: "bold",
    maxWidth: "260px",
    maxHeight: "60px",
    minWidth: "160px",
    minHeight: "50px",
    "@media (max-width: 769px)": {
      maxWidth: "260px",
      maxHeight: "60px",
      minWidth: "120px",
      minHeight: "50px",
    },
  },
  childButton: {
    background:
      "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
    color: "#fafafa",
    fontWeight: "bold",
    maxWidth: "260px",
    maxHeight: "60px",
    minWidth: "160px",
    minHeight: "50px",
    "@media (max-width: 769px)": {
      maxWidth: "260px",
      maxHeight: "60px",
      minWidth: "120px",
      minHeight: "50px",
    },
  },
  applyButton: {
    background:
      "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
    color: "#fafafa",
    fontWeight: "bold",
    maxWidth: "260px",
    maxHeight: "60px",
    minWidth: "160px",
    minHeight: "50px",
    marginTop: "20px",
    "@media (max-width: 2560px)": {
      marginTop: "20px",
      float: "right",
    },
    "@media (max-width: 426px)": {
      marginTop: "20px",
      marginRight: "90px",
    },
    "@media (max-width: 376px)": {
      marginTop: "20px",
      marginRight: "65px",
    },
    "@media (max-width: 321px)": {
      marginTop: "20px",
      marginRight: "35px",
    },
  },
}));

const style = {
  labelStyle: {
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: "#5c9eec",
    fontSize: 17,
  },
  contentStyle: {
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: "#e5e9f2",
    fontSize: 14,
  },
  buttonStyle: {
    fontFamily: "sans-serif",
    marginLeft: "1365px",
    fontWeight: "bold",
    color: "#e5e9f2",
    fontSize: 14,
  },
};

const CustomerTable = (props) => {
  const user = useSelector((state) => state?.virtualizer?.currentUser);
  const email = user.email;
  const [openParent, setOpenParent] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const [customerData, setCustomerData] = useState({
    org: "",
    projectName: "",
    customHostName: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  //flag to determine if parent is created
  const mockData = [];
  const mocks = useSelector((state) => state?.virtualizer?.mocks);
  if (Object.keys(mocks).length > 0) {
    mockData.push(mocks?.routeManager);
  }

  // Flag to determine if customer data is available
  let isCustomerExist = false;
  if (Object.keys(props?.customer).length > 0) {
    isCustomerExist = true;
  }

  // store current values into customerData from event
  const fetchHandler = (event) => {
    setCustomerData({
      ...customerData,
      [event.target.name]: event.target.value,
    });
  };

  // create a new customer
  const dispatch = useDispatch();
  const saveHandler = async (event) => {
    event.preventDefault();
    const customerPayload = {
      customer: {
        emailId: email,
        org: customerData?.org,
        projectName: customerData?.projectName,
        customHostName: customerData?.customHostName.concat(config.domain),
      },
    };
    await dispatch(createCustomer(customerPayload, email));
    enqueueSnackbar("Customer Created Successfully.", {
      variant: "success",
    });
  };

  const handleClickOpenParent = () => {
    setOpenParent(true);
  };
  const handleCloseParent = () => {
    setOpenParent(false);
  };
  const handleClickOpenChild = () => {
    setOpenChild(true);
  };
  const handleCloseChild = () => {
    setOpenChild(false);
  };

  const customer = useSelector((state) => state?.virtualizer?.customers);
  const hostName = [];
  let host = customer?.customer?.customHostName;
  if (host !== undefined && host.includes(config.domain)) {
    hostName.push(host.split(config.domain, 1));
  }

  // props object for parent and child dialog
  const actionProps = {
    editStatus: props?.editStatus,
    setEditStatus: props?.setEditStatus,
    rowEditData: props?.rowEditData,
    dispatchHost: hostName.flat(),
    editChild: props?.editChild,
    editParent: props?.editParent,
    openParent: openParent,
    setOpenParent: setOpenParent,
    openChild: openChild,
    setOpenChild: setOpenChild,
    handleClickOpenParent: handleClickOpenParent,
    handleCloseParent: handleCloseParent,
    handleClickOpenChild: handleClickOpenChild,
    handleCloseChild: handleCloseChild,
  };

  const copyToClipBoard = async (copyMe) => {
    await navigator.clipboard.writeText(copyMe);
    enqueueSnackbar("copied to clipboard.", {
      variant: "success",
    });
  };

  const classes = useStyles();

  /**
   *  Template to show customer details
   * @returns {JSX.Element}
   */
  const showCustomerInfoTemplate = () => {
    return (
      <div className="row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="bmd-label-floating" style={style.labelStyle}>
              Organization
            </label>
            <input
              required
              type="text"
              name="org"
              style={style.contentStyle}
              className="form-control"
              value={props.customer?.customer?.org}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="bmd-label-floating" style={style.labelStyle}>
              Project
            </label>
            <input
              required
              type="text"
              name="project"
              style={style.contentStyle}
              className="form-control"
              value={props.customer?.customer?.projectName}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="bmd-label-floating" style={style.labelStyle}>
              Custom Host Name
            </label>
            <input
              required
              type="text"
              style={style.contentStyle}
              className="form-control"
              name="host"
              value={props.customer?.customer?.customHostName}
              disabled
            />
            <Icon
              link
              name="copy"
              style={{
                color: "#e5e9f2",
                float: "right",
                marginTop: "-30px",
              }}
              onClick={() => copyToClipBoard(hostName.flat())}
            />
            {/* Section to display button behaviour based on mock data:: start */}
            {mockData.flat().length > 0 ? (
              <div className="addMockButton">
                {/* parentMmock */}
                <Button
                  variant="contained"
                  className={classes.parentButton}
                  size="large"
                  color="primary"
                  onClick={handleClickOpenParent}
                >
                  Add Parent
                </Button>
                <ParentDialog {...actionProps} />

                {/* childMock */}
                <Button
                  variant="contained"
                  className={classes.childButton}
                  size="large"
                  color="primary"
                  onClick={handleClickOpenChild}
                >
                  Add Child
                </Button>
                <ChildDialog {...actionProps} />
              </div>
            ) : (
              <div className="addParent">
                <Button
                  variant="contained"
                  className={classes.parentButton}
                  size="large"
                  color="primary"
                  onClick={handleClickOpenParent}
                >
                  Add Parent
                </Button>
                <ParentDialog {...actionProps} />
              </div>
            )}
            {/* Section to display button behaviour based on mock data:: end */}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Template to create a customer
   * @returns {JSX.Element}
   */
  const addCustomerInfoTemplate = () => {
    return (
      <div className="row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="bmd-label-floating" style={style.labelStyle}>
              Organization
            </label>
            <input
              required
              type="text"
              name="org"
              style={style.contentStyle}
              className="form-control"
              onChange={(event) => fetchHandler(event)}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="bmd-label-floating" style={style.labelStyle}>
              Project
            </label>
            <input
              required
              type="text"
              name="projectName"
              style={style.contentStyle}
              className="form-control"
              onChange={(event) => fetchHandler(event)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="bmd-label-floating" style={style.labelStyle}>
              Custom Host Name
            </label>
            <input
              required
              type="text"
              style={style.contentStyle}
              className="form-control"
              name="customHostName"
              onChange={(event) => fetchHandler(event)}
            />
            <div className=".apply">
              <Button
                variant="contained"
                className={classes.applyButton}
                size="large"
                color="primary"
                onClick={(event) => saveHandler(event)}
              >
                APPLY
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div
            className="card"
            style={{
              margin: "100px 0 50px 0",
              background:
                "linear-gradient(45deg, rgba(0,27,75,1) 0%, rgba(0,9,53,1) 100%)",
            }}
          >
            <div
              className="card-header card-header-primary"
              style={{
                background:
                  "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
                textShadow: "2px 2px #000000",
              }}
            >
              <h3
                className="card-title"
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  fontSize: "large",
                }}
              >
                Customer
              </h3>
            </div>
            <div className="mockStyle">
              <form>
                {props?.disabled && isCustomerExist
                  ? showCustomerInfoTemplate()
                  : addCustomerInfoTemplate()}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;
