import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseInit = firebase.initializeApp({
  apiKey: process.env.REACT_APP_IDENTITY_PLATFROM_API_KEY,
  authDomain: process.env.REACT_APP_IDENTITY_PLATFROM_AUTHDOMAIN,
});

export const provider = new GoogleAuthProvider();
export const auth = firebaseInit.auth();
export default firebaseInit;
