import React from "react";

export const GIPErrorMessage = (data) => {
  switch (data) {
    case "auth/popup-closed-by-user":
      return null;
    case "auth/email-already-in-use":
      return "The email address is already in use by another account";
    case "auth/wrong-password":
      return "The password is invalid or the user does not have a password";
    case "auth/user-not-found":
      return "There is no user record corresponding to this identifier. The user may have been deleted";
    default:
      return "Error occured, Please try again!";
  }
};

export default GIPErrorMessage;
