import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ParentPane from "../ParentPane";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    marginBottom: "-10px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog: {
    margin: "50px 20px 0px 20px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ParentDialog = (props) => {
  const classes = useStyles();

  const actionPropsParent = {
    editStatus: props?.editStatus,
    setEditStatus: props?.setEditStatus,
    rowEditData: props?.rowEditData,
    dispatchHost: props?.dispatchHost,
    editParent: props?.editParent,
    handleCloseParent: props?.handleCloseParent,
    handleEditClose: props?.handleEditClose,
  };

  const handleEditClose = (event) => {
    return props?.handleEditClose(event);
  };

  const handleViewClose = (event) => {
    return props?.handleViewClose(event);
  };

  const handleCloseParent = (event) => {
    if (props?.editStatus) {
      handleEditClose(event);
    }
    if (props?.openView) {
      handleViewClose(event);
    }
    return props?.handleCloseParent(event);
  };
  return (
    <Dialog
      PaperProps={{
        style: {
          background: "linear-gradient(225deg,#001b4b 0%,#000935 100%)",
        },
      }}
      fullScreen
      open={
        props?.openParent ? props?.handleClickOpenParent : props?.openParent
      }
      onClose={props?.handleCloseParent}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar
          style={{
            background:
              "linear-gradient(45deg, #5c9eec -100%, #001b4b 112%, #001b4b 100% )",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={(event) => handleCloseParent(event)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add Parent Mock
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.dialog}>
        {props?.openView ? (
          <ParentPane
            editStatus={props?.editStatus}
            setEditStatus={props?.setEditStatus}
            rowEditData={props?.rowEditData}
            dispatchHost={props?.dispatchHost}
            editParent={props?.editParent}
            handleCloseParent={props?.handleCloseParent}
            handleEditClose={props?.handleEditClose}
            openView={props?.openView}
          />
        ) : (
          <ParentPane {...actionPropsParent} />
        )}
      </div>
    </Dialog>
  );
};

export default ParentDialog;
