import React from "react";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FeatureCard from "./FeatureCard";
import "../../assets/css/Home/Features.css";

export default function Features() {
  const data = require("../../config/config");
  return (
    <Box className="feature-root">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className="feature-heading" variant="h3" align="center">
          Features
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={5}
      >
        {data.feature[1].featureCard.map((feature, key) => (
          <Grid item key={feature.id}>
            <FeatureCard feature={feature} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
