import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import NewNavBar from "./components/NewNavbarContent";
import "semantic-ui-css/semantic.min.css";
import Profile from "./views/Profile";
import Home from "./views/HomePageNew";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Route, Switch } from "react-router-dom";
import Loading from "./components/AppLoadingBar";
import { DashBoard } from "./views";
import Footer from "./views/Footer";
import UserSignUp from "./security/googleIdentity/UserSignUp";
import { SnackbarProvider } from "notistack";
import Zoom from "@material-ui/core/Zoom";
import UserSignIn from "./security/googleIdentity/UserSignIn";
import PrivateRoute from "./security/googleIdentity/private-route";

const App = () => {
  return (
    <div id="app">
      <Helmet>
        <style>{"body { background-color: #000935; }"}</style>
      </Helmet>
      <NewNavBar />
      <SnackbarProvider
        preventDuplicate
        maxSnack={5}
        TransitionComponent={Zoom}
      >
        <Switch>
          <Route path="/home" exact component={Loading} />
          <Route path="/" exact component={Home} />
          <PrivateRoute path="/dashboard" component={DashBoard} />
          <PrivateRoute path="/profile" component={Profile} />
          <Route path="/signin" exact component={UserSignIn} />
          <Route path="/signup" exact component={UserSignUp} />
        </Switch>
      </SnackbarProvider>
      <Footer />
    </div>
  );
};

export default App;
