import React, { useState, useEffect } from "react";
import "../assets/css/material-dashboard.css";
import "../assets/css/dialog.css";
import { Icon } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import CustomerTable from "./CustomerTable";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCustomerByEmailId,
  fetchAllCustomerMocks,
  deleteMock,
} from "../store/actions/actions";
import consola from "consola";
import ChildDialog from "./ParentAndChildMock/ChildDialog";
import ParentDialog from "./ParentAndChildMock/ParentDialog";
import { useSnackbar } from "notistack";

const config = require("../config/config");

const MainContent = () => {
  const user = useSelector((state) => state?.virtualizer?.currentUser);
  const email = user.email;
  const [activeKey, setActiveKey] = useState("");
  const [rowEditData, setRowEditData] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [editParent, setEditParent] = useState(false);
  const [editChild, setEditChild] = useState(false);
  const [openView, setOpenView] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const style = {
    root: {
      backgroundColor: "#000935",
      boxShadow: "0 8px 40px 0 rgba(0,0,0,1)",
    },
    card: {
      background:
        "linear-gradient(45deg, rgba(0,27,75,1) 0%, rgba(0,9,53,1) 100%)",
      boxShadow: "0 8px 40px 0 rgba(0,0,0,1)",
    },
    labelStyle: {
      fontFamily: "sans-serif",
      fontWeight: "bold",
      color: "#5c9eec",
      fontSize: 15,
    },
    contentStyle: {
      fontFamily: "sans-serif",
      fontWeight: "bold",
      color: "#e5e9f2",
      fontSize: 12,
    },
    noContentStyle: {
      display: "flex",
      justifyContent: "center",
      fontFamily: "sans-serif",
      fontWeight: "bold",
      color: "#e5e9f2",
      fontSize: 15,
    },
  };

  // To open  the dialog box set states for parent and child and also the edit status
  const handleClickEdit = (collapseIndex, tabIndex, action, rowData) => {
    setEditStatus(true);
    setRowEditData(rowData);
    if (action === "child") {
      setEditChild(true);
      setEditParent(false);
      setOpenChild(true);
    } else if (action === "parent") {
      setEditParent(true);
      setEditChild(false);
      setOpenParent(true);
    }
  };

  // to make the edit status false
  const handleEditClose = () => {
    setEditStatus(false);
  };

  // event handlers for action View
  const handleViewOpen = (value, tab) => {
    setOpenView(true);
    setEditStatus(true);
    setRowEditData(value);
    if (tab === "child") {
      setEditChild(true);
      setEditParent(false);
      setOpenChild(true);
    } else if (tab === "parent") {
      setEditParent(true);
      setEditChild(false);
      setOpenParent(true);
    }
  };
  const handleViewClose = () => {
    setOpenView(false);
  };

  // Fetching the hostname to display mock
  const hostName = [];
  const customer = useSelector((state) => state?.virtualizer?.customers);
  if (customer) {
    let host = customer?.customer?.customHostName;
    if (host !== undefined) {
      hostName.push(host.split(config.domain, 1));
    }
  }

  // mockData ~ fetch all customer mocks from store to render inside the table
  const mockData = [];
  const mocks = useSelector((state) => state?.virtualizer?.mocks);
  if (Object.keys(mocks).length > 0) {
    mockData.push(mocks?.routeManager);
  } else {
    consola.warn("No mocks available!");
  }

  const param = hostName.flat();
  // setting state for warning dialog and open/close handler
  const [openWarning, setOpenWarning] = React.useState(false);

  const handleClickOpenWarning = () => {
    setOpenWarning(true);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  // to delete mock
  const deleteHandler = async (event, data) => {
    event.preventDefault();
    const payLoad = {
      emailId: email,
      routeManager: [
        {
          appName: data?.appName,
          type: "MOCK",
          heirarchy: "parent",
          pattern: data?.pattern,
          isDeleted: true,
        },
      ],
    };
    await dispatch(deleteMock(payLoad, param[0]));
    enqueueSnackbar("Deleted Parent and its Child Mocks.", {
      variant: "error",
    });
    handleCloseWarning();
  };

  useEffect(() => {
    if (Object.keys(customer).length === 0) {
      dispatch(fetchCustomerByEmailId(email));
    }

    if (mockData.length === 0) {
      dispatch(fetchAllCustomerMocks(param[0]));
    }
  }, [mockData]);

  // to set the states for the child and parent dialog box

  const [openParent, setOpenParent] = useState(false);
  const [openChild, setOpenChild] = useState(false);

  // dialog box state handler
  const handleDeleteChild = () => {
    enqueueSnackbar("Child Cannot Be Deleted", {
      variant: "error",
    });
  };
  const handleClickOpenParent = () => {
    setOpenParent(true);
  };
  const handleCloseParent = () => {
    setOpenParent(false);
  };
  const handleClickOpenChild = () => {
    setOpenChild(true);
  };
  const handleCloseChild = () => {
    setOpenChild(false);
  };

  /**
   * childData is the child of each parentData
   * @param child
   * @param key
   * @returns {JSX.Element}
   */
  const childData = (child, key) => {
    return (
      <tr key={key} style={style.contentStyle}>
        <td>{child?.appName}</td>
        <td>{child?.parent}</td>
        <td>{new Date(child?.createdTs).toUTCString()}</td>
        <td></td>
        <td>{child?.respCode}</td>
        <td>{child?.respContentType}</td>
        <td>{child?.respDelay}</td>
        <td>
          {/* View Mock */}
          <Icon
            link
            name="eye"
            onClick={() => handleViewOpen(child, "child")}
          />
          <ChildDialog
            editStatus={editStatus}
            setEditStatus={setEditStatus}
            rowEditData={rowEditData}
            dispatchHost={hostName.flat()}
            editChild={editChild}
            openChild={openChild}
            setOpenChild={setOpenChild}
            handleClickOpenChild={handleClickOpenChild}
            handleCloseChild={handleCloseChild}
            handleEditClose={handleEditClose}
            openView={openView}
            setOpenView={setOpenView}
            handleViewClose={handleViewClose}
          />
          {/* View Mock ends here*/}
          {/* Edit Mock */}
          <Icon
            link
            name="edit"
            onClick={() => handleClickEdit("0", 1, "child", child)}
          />
          <ChildDialog
            editStatus={editStatus}
            setEditStatus={setEditStatus}
            rowEditData={rowEditData}
            dispatchHost={hostName.flat()}
            editChild={editChild}
            openChild={openChild}
            setOpenChild={setOpenChild}
            handleClickOpenChild={handleClickOpenChild}
            handleCloseChild={handleCloseChild}
            handleEditClose={handleEditClose}
            openView={openView}
            setOpenView={setOpenView}
            handleViewClose={handleViewClose}
          />
          {/* Edit Mock ends here*/}
          <Icon link name="delete" onClick={() => handleDeleteChild()} />
        </td>
      </tr>
    );
  };

  /**
   * ParentData is the parent mock data rendering in the table
   * & routeChildEntry is a key in the JSON
   * @param parent
   * @param key
   * @returns {JSX.Element}
   */
  const parentData = (parent, key) => {
    return parent?.routeChildEntry ? (
      <>
        <tr key={key} style={style.contentStyle}>
          <td>{parent?.appName}</td>
          <td></td>
          <td>{new Date(parent?.createdTs).toUTCString()}</td>
          <td>{parent?.pattern}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            {/* View Mock */}
            <Icon
              link
              name="eye"
              onClick={() => handleViewOpen(parent, "parent")}
            />
            <ParentDialog
              disabled={true}
              editStatus={editStatus}
              setEditStatus={setEditStatus}
              rowEditData={rowEditData}
              dispatchHost={hostName.flat()}
              editParent={editParent}
              openParent={openParent}
              setOpenParent={setOpenParent}
              handleClickOpenParent={handleClickOpenParent}
              handleCloseParent={handleCloseParent}
              handleEditClose={handleEditClose}
              openView={openView}
              setOpenView={setOpenView}
              handleViewClose={handleViewClose}
            />
            {/* View Mock ends here*/}
            {/* Edit Mock */}
            <Icon
              link
              name="edit"
              onClick={() => handleClickEdit("0", 0, "parent", parent)}
            />
            <ParentDialog
              editStatus={editStatus}
              setEditStatus={setEditStatus}
              rowEditData={rowEditData}
              dispatchHost={hostName.flat()}
              editParent={editParent}
              openParent={openParent}
              setOpenParent={setOpenParent}
              handleClickOpenParent={handleClickOpenParent}
              handleCloseParent={handleCloseParent}
              handleEditClose={handleEditClose}
              openView={openView}
              setOpenView={setOpenView}
              handleViewClose={handleViewClose}
            />
            {/* Edit Mock ends here */}
            <Icon link name="delete" onClick={() => handleClickOpenWarning()} />
            {/* {delete warning dialog starts here} */}
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "#001b4b",
                },
              }}
              open={openWarning}
              onClose={handleCloseWarning}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{
                  background:
                    "linear-gradient(45deg, #5c9eec -100%, #001b4b 112%, #001b4b 100% )",
                  color: "white",
                }}
              >
                {"Do you want to delete parent?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    color: "white",
                  }}
                >
                  Deleting parent would delete its child. Do you want to
                  continue ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    color: "#05daed",
                  }}
                  onClick={handleCloseWarning}
                  color="primary"
                >
                  Disagree
                </Button>
                <Button
                  style={{
                    color: "#05daed",
                  }}
                  onClick={(event) => deleteHandler(event, parent, "PARENT")}
                  color="primary"
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            {/* {delete warning dialog ends here} */}
          </td>
        </tr>
        {parent?.routeChildEntry.map(childData)}
      </>
    ) : (
      <tr key={key} style={style.contentStyle}>
        <td>{parent?.appName}</td>
        <td></td>
        <td>{new Date(parent?.createdTs).toUTCString()}</td>
        <td>{parent?.pattern}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  };

  return (
    <div className={style.root}>
      <CustomerTable
        disabled={hostName.length > 0}
        customer={customer}
        activeKey={activeKey}
        editStatus={editStatus}
        setEditstatus={setEditStatus}
        rowEditData={rowEditData}
        editChild={editChild}
        setEditChild={setEditChild}
        editParent={editParent}
        setEditParent={setEditParent}
      />
      {/* Mock table starts here */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div
              className="card"
              style={{
                margin: "50px 0 100px 0",
                background:
                  "linear-gradient(45deg, rgba(0,27,75,1) 0%, rgba(0,9,53,1) 100%)",
              }}
            >
              <div
                className="card-header card-header-info"
                style={{
                  background:
                    "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
                  textShadow: "2px 2px #000000",
                }}
              >
                <h3
                  className="card-title"
                  style={{
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                    fontSize: "large",
                  }}
                >
                  Mock
                </h3>
              </div>
              <div className="card-body" style={style.card}>
                {mockData.flat().length > 0 ? (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={style.labelStyle}>AppName</th>
                          <th style={style.labelStyle}>Parent</th>
                          <th style={style.labelStyle}>Created Date</th>
                          <th style={style.labelStyle}>Request Path</th>
                          <th style={style.labelStyle}>Response Code</th>
                          <th style={style.labelStyle}>Content Type</th>
                          <th style={style.labelStyle}>Response Delay (ms)</th>
                          <th style={style.labelStyle}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mockData.map((data, key) => {
                          return data.map(parentData, key);
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={style.labelStyle}>AppName</th>
                          <th style={style.labelStyle}>Parent</th>
                          <th style={style.labelStyle}>Created Date</th>
                          <th style={style.labelStyle}>Request Path</th>
                          <th style={style.labelStyle}>Response Code</th>
                          <th style={style.labelStyle}>Content Type</th>
                          <th style={style.labelStyle}>Response Delay (ms)</th>
                          <th style={style.labelStyle}>Action</th>
                        </tr>
                      </thead>
                    </table>
                    <h3 style={style.noContentStyle}>
                      No mocks available to display.
                    </h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mock table ends here */}
    </div>
  );
};

export default MainContent;
