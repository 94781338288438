import React, { useState } from "react";
import { Form, TextArea, Button } from "semantic-ui-react";
import "../../src/assets/css/addMock.css";
import Info from "./Info";
import { useSelector, useDispatch } from "react-redux";
import { ParentOrChildMock } from "../store/actions/actions";
import consola from "consola";
import { useSnackbar } from "notistack";

let config = require("../config/config");
let contentTypes = require("../config/contentTypes");

export const UsecasePane = (props) => {
  const dispatch = useDispatch();
  const [app, setApp] = useState("");
  const [des, setDes] = useState("");
  const [req, setReq] = useState("");
  const [res, setRes] = useState("");
  const [resCode, setResCode] = useState("");
  const [content, setContent] = useState("");
  const [delay, setDelay] = useState("");
  const [url, setUrl] = useState("");
  const [selectparent, setSelectParent] = useState("");
  const [reqMethod, setreqMethod] = useState("");
  const [reqHeader, setreqHeader] = useState("");
  const [headerKey, setHeaderKey] = useState("");
  const [headerValue, setHeaderValue] = useState("");
  const [paramKey, setParamKey] = useState("");
  const [paramValue, setParamValue] = useState("");
  const [rule, setRule] = useState("");
  const [ruleKey, setRuleKey] = useState("");
  const [ruleValue, setRuleValue] = useState("");
  const user = useSelector((state) => state?.virtualizer?.currentUser);
  const email = user.email;
  const { enqueueSnackbar } = useSnackbar();

  const style = {
    labelStyle: {
      fontWeight: "bold",
      color: "#ffff",
      fontSize: 15,
    },
  };

  //to iterate through all the mocks to fetch the appName
  const appNames = [];
  const mocks = useSelector((state) => state?.virtualizer?.mocks?.routeManager);
  mocks.map((mock, index) => {
    appNames.push({
      key: index,
      text: mock.appName,
      value: mock.appName,
    });
  });

  const handleCloseChild = (event) => {
    return props?.handleCloseChild(event);
  };
  // to make edit status false
  const handleEditClose = (event) => {
    return props?.handleEditClose(event);
  };

  /**
   *  create or update child handler
   * @param event
   * @param type
   * @returns {Promise<void>}
   */
  const saveOrUpdateChildMock = async (event, type) => {
    event.preventDefault();
    const childModel = {
      emailId: email,
      routeManager: [
        {
          appName: type === "UPDATE" ? props?.rowEditData?.appName : app,
          description: des,
          type: "MOCK",
          heirarchy: "child",
          parent: type === "UPDATE" ? props?.rowEditData?.parent : selectparent,
          pattern: "",
          reqBody: req,
          reqHeader: reqHeader
            ? reqHeader + ":" + headerKey + "=" + headerValue
            : "",
          reqMethod: reqMethod,
          reqParameter: paramKey ? paramKey + "=" + paramValue : "",
          respBody: res,
          respCode: resCode,
          respContentType: content,
          respDelay: type === "UPDATE" ? props?.rowEditData?.respDelay : 0,
          mockRule: ruleKey ? ruleKey + rule + ruleValue : "",
          url: type === "UPDATE" ? props?.rowEditData?.url : url,
        },
      ],
    };

    if (Array.isArray(props?.dispatchHost) && props?.dispatchHost.length > 0) {
      switch (type) {
        case "CREATE":
          await dispatch(
            ParentOrChildMock(childModel, props?.dispatchHost[0], type)
          );
          handleCloseChild(event);
          enqueueSnackbar("Child Mock Created Successfully", {
            variant: "success",
          });
          break;
        case "UPDATE":
          await dispatch(
            ParentOrChildMock(childModel, props?.dispatchHost[0], type)
          );
          handleCloseChild(event);
          handleEditClose(event);
          enqueueSnackbar("Child Mock Updated Successfully", {
            variant: "success",
          });
          break;
        default:
          consola.error("Action type is not defined for the dispatch.");
      }
    }
  };

  /**
   * Template to update or view child
   * @returns {JSX.Element}
   */
  const updateOrViewChildTemplate = () => {
    if (props?.openView) {
      return (
        <div style={{ cursor: "none", pointerEvents: "none" }}>
          <Form.Input
            required
            style={{ pointerEvents: "none" }}
            id="app"
            label="Application Name"
            name="appName"
            value={props?.rowEditData?.appName}
            readOnly="appName"
          />
          <Form.Select
            style={{ pointerEvents: "none" }}
            fluid
            label="Parent Application"
            name="parent"
            placeholder="Select Parent AppName"
            value={props?.rowEditData?.parent}
            options={appNames}
            readOnly="value"
          />
          <Form.Input
            style={{ pointerEvents: "none" }}
            fluid
            label="Description"
            name="description"
            defaultValue={props?.rowEditData?.description}
            readOnly="description"
          />
          <Form.Select
            style={{ pointerEvents: "none" }}
            required
            fluid
            id="reqMethod"
            label="Request Method"
            placeholder="Select the Request Method"
            options={config.requestMethods}
            name="reqMethod"
            defaultValue={props?.rowEditData?.reqMethod}
            readOnly="reqMethod"
          />
          <Form.Group widths="equal">
            <Form.Select
              style={{ pointerEvents: "none" }}
              fluid
              id="reqHed"
              label="Request Header"
              placeholder="Select the Header"
              name="reqHeader"
              options={config.header}
              defaultValue={props?.rowEditData?.reqHeader.split(":")[0]}
              readOnly="reqHeader"
            />
            <Form.Input
              style={{ pointerEvents: "none" }}
              fluid
              id="headerKey"
              label="Name"
              placeholder="Name"
              name="headerKey"
              defaultValue={
                props?.rowEditData?.reqHeader.split(":")[1].split("=")[0]
              }
              readOnly="headerKey"
            />
            <Form.Input
              style={{ pointerEvents: "none" }}
              fluid
              id="headerValue"
              label="Value"
              placeholder="Value"
              name="headerValue"
              defaultValue={
                props?.rowEditData?.reqHeader.split(":")[1].split("=")[1]
              }
              readOnly="headerValue"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              style={{ pointerEvents: "none" }}
              fluid
              id="paramKey"
              label="Request Parameter"
              name="reqParam"
              defaultValue={props?.rowEditData?.paramKey}
              readOnly="reqParam"
            />
            <Form.Input
              style={{ pointerEvents: "none" }}
              fluid
              id="paramValue"
              label="Value"
              placeholder="Value"
              name="paramValue"
              defaultValue={props?.rowEditData?.paramValue}
              readOnly="paramValue"
            />
          </Form.Group>
          <Form.Field
            style={{
              background: "#001b4b",
              color: "white",
              pointerEvents: "none",
            }}
            fluid
            control={TextArea}
            label="Request Body"
            name="reqBody"
            defaultValue={props?.rowEditData?.reqBody}
            readOnly="reqBody"
          />
          <Form.Field
            style={{
              background: "#001b4b",
              color: "white",
              pointerEvents: "none",
            }}
            fluid
            control={TextArea}
            label="Response Body"
            name="respBody"
            defaultValue={props?.rowEditData?.respBody}
            readOnly="respBody"
          />
          <Form.Input
            style={{ pointerEvents: "none" }}
            required
            fluid
            label="Response Code"
            name="respCode"
            defaultValue={props?.rowEditData?.respCode}
            readOnly="respCode"
          />
          <Form.Select
            style={{ pointerEvents: "none" }}
            required
            fluid
            id="respContentType"
            label="Content Type"
            placeholder="Select the Content Type"
            options={contentTypes.sortedContentTypes}
            name="respContentType"
            defaultValue={props?.rowEditData?.respContentType}
            readOnly="respContentType"
          />
          <Form.Input
            style={{ pointerEvents: "none" }}
            fluid
            label="Response Delay(ms)"
            name="respDelay"
            defaultValue={props?.rowEditData?.respDelay}
            readOnly="respDelay"
          />
          <Form.Input
            style={{ pointerEvents: "none" }}
            fluid
            label="URL"
            name="url"
            defaultValue={props?.rowEditData?.url}
            readOnly="url"
          />
          <Form.Group widths="equal">
            <Form.Input
              style={{ pointerEvents: "none" }}
              fluid
              id="ruleKey"
              label="Hybrid Rule"
              placeholder="Response Code"
              name="ruleKey"
              defaultValue={props?.rowEditData?.ruleKey}
              readOnly="ruleKey"
            />
            <Info />
            <Form.Select
              style={{ pointerEvents: "none" }}
              fluid
              id="condition"
              label="Condition"
              name="condition"
              options={config.hybridSelect}
              defaultValue={props?.rowEditData?.rule}
              readOnly="condition"
            />
            <Form.Input
              style={{ pointerEvents: "none" }}
              fluid
              id="checkValue"
              label="Value"
              name="checkValue"
              defaultValue={props?.rowEditData?.ruleValue}
              readOnly="checkValue"
            />
          </Form.Group>
        </div>
      );
    } else {
      return (
        <div>
          <Form.Input
            required
            style={{ pointerEvents: "none" }}
            id="app"
            label="Application Name"
            name="appName"
            value={props?.rowEditData?.appName}
            onChange={(event) => setApp(event.target.value)}
          />
          <Form.Select
            fluid
            style={{ pointerEvents: "none" }}
            label="Parent Application"
            name="parent"
            placeholder="Select Parent AppName"
            onChange={(e, { value }) => {
              setSelectParent(value);
            }}
            value={props?.rowEditData?.parent}
            options={appNames}
          />
          <Form.Input
            fluid
            label="Description"
            name="description"
            defaultValue={props?.rowEditData?.description}
            onChange={(event) => setDes(event.target.value)}
          />
          <Form.Select
            required
            fluid
            id="reqMethod"
            label="Request Method"
            placeholder="Select the Request Method"
            options={config.requestMethods}
            name="reqMethod"
            defaultValue={props?.rowEditData?.reqMethod}
            onChange={(e, { value }) => {
              setreqMethod(value);
            }}
          />
          <Form.Group widths="equal">
            <Form.Select
              fluid
              id="reqHed"
              label="Request Header"
              placeholder="Select the Header"
              name="reqHeader"
              options={config.header}
              defaultValue={props?.rowEditData?.reqHeader}
              onChange={(e, { value }) => {
                setreqHeader(value);
              }}
            />
            <Form.Input
              fluid
              id="headerKey"
              label="Name"
              placeholder="Name"
              name="headerKey"
              defaultValue={props?.rowEditData?.headerKey}
              onChange={(event) => setHeaderKey(event.target.value)}
            />
            <Form.Input
              fluid
              id="headerValue"
              label="Value"
              placeholder="Value"
              name="headerValue"
              defaultValue={props?.rowEditData?.headerValue}
              onChange={(event) => setHeaderValue(event.target.value)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              id="paramKey"
              label="Request Parameter"
              name="reqParam"
              defaultValue={props?.rowEditData?.paramKey}
              onChange={(event) => setParamKey(event.target.value)}
            />
            <Form.Input
              fluid
              id="paramValue"
              label="Value"
              placeholder="Value"
              defaultValue={props?.rowEditData?.paramValue}
              onChange={(event) => setParamValue(event.target.value)}
            />
          </Form.Group>
          <Form.Field
            style={{ background: "#001b4b", color: "white" }}
            fluid
            control={TextArea}
            label="Request Body"
            name="reqBody"
            defaultValue={props?.rowEditData?.reqBody}
            onChange={(event) => setReq(event.target.value)}
          />
          <Form.Field
            style={{ background: "#001b4b", color: "white" }}
            fluid
            control={TextArea}
            label="Response Body"
            name="respBody"
            defaultValue={props?.rowEditData?.respBody}
            onChange={(event) => setRes(event.target.value)}
          />
          <Form.Input
            required
            fluid
            label="Response Code"
            name="respCode"
            defaultValue={props?.rowEditData?.respCode}
            onChange={(event) => setResCode(event.target.value)}
          />
          <Form.Select
            required
            fluid
            id="respContentType"
            label="Content Type"
            placeholder="Select the Content Type"
            options={contentTypes.sortedContentTypes}
            name="respContentType"
            defaultValue={props?.rowEditData?.respContentType}
            onChange={(e, { value }) => {
              setContent(value);
            }}
          />
          <Form.Input
            fluid
            label="Response Delay(ms)"
            name="respDelay"
            defaultValue={props?.rowEditData?.respDelay}
            onChange={(event) => setDelay(event.target.value)}
          />
          <Form.Input
            fluid
            label="URL"
            name="url"
            defaultValue={props?.rowEditData?.url}
            onChange={(event) => setUrl(event.target.value)}
          />
          <Form.Group widths="equal">
            <Form.Input
              fluid
              id="ruleKey"
              label="Hybrid Rule"
              placeholder="Response Code"
              defaultValue={props?.rowEditData?.ruleKey}
              onChange={(event) => setRuleKey(event.target.value)}
            />
            <Info />
            <Form.Select
              fluid
              id="condition"
              label="Condition"
              options={config.hybridSelect}
              defaultValue={props?.rowEditData?.rule}
              onChange={(e, { value }) => {
                setRule(value);
              }}
            />
            <Form.Input
              fluid
              id="checkValue"
              label="Value"
              defaultValue={props?.rowEditData?.ruleValue}
              onChange={(event) => setRuleValue(event.target.value)}
            />
          </Form.Group>
          <Button
            className="submitButton"
            style={style.labelStyle}
            onClick={(event) => saveOrUpdateChildMock(event, "UPDATE")}
          >
            Save
          </Button>
        </div>
      );
    }
  };

  /**
   * Template to create child
   * @returns {JSX.Element}
   */
  const createChildTemplate = () => {
    return (
      <div>
        <Form.Input
          required
          id="app"
          label="Application Name"
          name="appName"
          onChange={(event) => setApp(event.target.value)}
          style={{ color: "white" }}
        />
        <Form.Select
          fluid
          style={{ color: "white" }}
          label="Parent Application"
          name="parent"
          placeholder="Select Parent AppName"
          onChange={(e, { value }) => {
            setSelectParent(value);
          }}
          options={appNames}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "hotpink",
              primary: "black",
            },
          })}
        />
        <Form.Input
          fluid
          label="Description"
          name="description"
          onChange={(event) => setDes(event.target.value)}
        />
        <Form.Select
          required
          fluid
          id="reqMethod"
          label="Request Method"
          placeholder="Select the Request Method"
          options={config.requestMethods}
          name="reqMethod"
          onChange={(e, { value }) => {
            setreqMethod(value);
          }}
        />
        <Form.Group widths="equal">
          <Form.Select
            fluid
            id="reqHed"
            label="Request Header"
            placeholder="Select the Header"
            name="reqHeader"
            options={config.header}
            onChange={(e, { value }) => {
              setreqHeader(value);
            }}
          />
          <Form.Input
            fluid
            id="headerKey"
            label="Name"
            placeholder="Name"
            name="headerKey"
            onChange={(event) => setHeaderKey(event.target.value)}
          />
          <Form.Input
            fluid
            id="headerValue"
            label="Value"
            placeholder="Value"
            name="headerValue"
            onChange={(event) => setHeaderValue(event.target.value)}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            id="paramKey"
            label="Request Parameter"
            name="reqParam"
            onChange={(event) => setParamKey(event.target.value)}
          />
          <Form.Input
            fluid
            id="paramValue"
            label="Value"
            placeholder="Value"
            onChange={(event) => setParamValue(event.target.value)}
          />
        </Form.Group>
        <Form.Field
          style={{ background: "#001b4b", color: "white" }}
          fluid
          control={TextArea}
          label="Request Body"
          name="reqBody"
          onChange={(event) => setReq(event.target.value)}
        />
        <Form.Field
          style={{ background: "#001b4b", color: "white" }}
          fluid
          control={TextArea}
          label="Response Body"
          name="respBody"
          onChange={(event) => setRes(event.target.value)}
        />
        <Form.Input
          required
          fluid
          label="Response Code"
          name="respCode"
          onChange={(event) => setResCode(event.target.value)}
        />
        <Form.Select
          required
          fluid
          id="respContentType"
          label="Content Type"
          placeholder="Select the Content Type"
          options={contentTypes.sortedContentTypes}
          name="respContentType"
          defaultValue={props?.rowEditData?.respContentType}
          onChange={(e, { value }) => {
            setContent(value);
          }}
        />
        <Form.Input
          fluid
          label="Response Delay(ms)"
          name="respDelay"
          onChange={(event) => setDelay(event.target.value)}
        />
        <Form.Input
          fluid
          label="URL"
          name="url"
          onChange={(event) => setUrl(event.target.value)}
        />
        <Form.Group widths="equal">
          <Form.Input
            fluid
            id="ruleKey"
            label="Hybrid Rule"
            placeholder="Response Code"
            onChange={(event) => setRuleKey(event.target.value)}
          />
          <Info />
          <Form.Select
            fluid
            id="condition"
            label="Condition"
            options={config.hybridSelect}
            onChange={(e, { value }) => {
              setRule(value);
            }}
          />
          <Form.Input
            fluid
            id="checkValue"
            label="Value"
            onChange={(event) => setRuleValue(event.target.value)}
          />
        </Form.Group>
        <Button
          className="submitButton"
          style={style.labelStyle}
          onClick={(event) => saveOrUpdateChildMock(event, "CREATE")}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <Form size="large" inverted className="parentStyle">
      {props?.editStatus && props?.editChild
        ? updateOrViewChildTemplate()
        : createChildTemplate()}
    </Form>
  );
};

export default UsecasePane;
