export const content_Types = [
  { key: "", text: "", value: "" },
  {
    key: "atom+xml",
    text: "application/atom+xml",
    value: "application/atom+xml",
  },
  {
    key: "ecmascript",
    text: "application/ecmascript",
    value: "application/ecmascript",
  },
  { key: "json", text: "application/json", value: "application/json" },
  {
    key: "vnd.api+json",
    text: "application/vnd.api+json",
    value: "application/vnd.api+json",
  },
  {
    key: "javascript",
    text: "application/javascript",
    value: "application/javascript",
  },
  {
    key: "octet-stream",
    text: "application/octet-stream",
    value: "application/octet-stream",
  },
  { key: "ogg", text: "application/ogg", value: "application/ogg" },
  { key: "pdf", text: "application/pdf", value: "application/pdf" },
  {
    key: "postscript",
    text: "application/postscript",
    value: "application/postscript",
  },
  {
    key: "rdf+xml",
    text: "application/rdf+xml",
    value: "application/rdf+xml",
  },
  {
    key: "rss+xml",
    text: "application/rss+xml",
    value: "application/rss+xml",
  },
  {
    key: "soap+xml",
    text: "application/soap+xml",
    value: "application/soap+xml",
  },
  {
    key: "font-woff",
    text: "application/font-woff",
    value: "application/font-woff",
  },
  { key: "x-yaml", text: "application/x-yaml", value: "application/x-yaml" },
  {
    key: "xhtml+xml",
    text: "application/xhtml+xml",
    value: "application/xhtml+xml",
  },
  { key: "xml", text: "application/xml", value: "application/xml" },
  {
    key: "xml-dtd",
    text: "application/xml-dtd",
    value: "application/xml-dtd",
  },
  {
    key: "xop-xml",
    text: "application/xop-xml",
    value: "application/xop-xml",
  },
  { key: "zip", text: "application/zip", value: "application/zip" },
  { key: "gzip", text: "application/gzip", value: "application/gzip" },
  {
    key: "graphql",
    text: "application/graphql",
    value: "application/graphql",
  },
  {
    key: "x-www-form-urlencoded",
    text: "application/x-www-form-urlencoded",
    value: "application/x-www-form-urlencoded",
  },
  { key: "basic", text: "audio/basic", value: "audio/basic" },
  { key: "L24", text: "audio/L24", value: "audio/L24" },
  { key: "mp4", text: "audio/mp4", value: "audio/mp4" },
  { key: "mpeg", text: "audio/mpeg", value: "audio/mpeg" },
  { key: "ogg", text: "audio/ogg", value: "audio/ogg" },
  { key: "vorbis", text: "audio/vorbis", value: "audio/vorbis" },
  {
    key: "vnd.rn-realaudio",
    text: "audio/vnd.rn-realaudio",
    value: "audio/vnd.rn-realaudio",
  },
  { key: "vnd.wave", text: "audio/vnd.wave", value: "audio/vnd.wave" },
  { key: "webm", text: "audio/webm", value: "audio/webm" },
  { key: "gif", text: "image/gif", value: "image/gif" },
  { key: "jpeg", text: "image/jpeg", value: "image/jpeg" },
  { key: "pjpeg", text: "image/pjpeg", value: "image/pjpeg" },
  { key: "png", text: "image/png", value: "image/png" },
  { key: "svg+xml", text: "image/svg+xml", value: "image/svg+xml" },
  { key: "tiff", text: "image/tiff", value: "image/tiff" },
  { key: "http", text: "message/http", value: "message/http" },
  { key: "imdn+xml", text: "message/imdn+xml", value: "message/imdn+xml" },
  { key: "partial", text: "message/partial", value: "message/partial" },
  { key: "rfc822", text: "message/rfc822", value: "message/rfc822" },
  { key: "mixed", text: "multipart/mixed", value: "multipart/mixed" },
  {
    key: "alternative",
    text: "multipart/alternative",
    value: "multipart/alternative",
  },
  { key: "related", text: "multipart/related", value: "multipart/related" },
  {
    key: "form-data",
    text: "multipart/form-data",
    value: "multipart/form-data",
  },
  { key: "signed", text: "multipart/signed", value: "multipart/signed" },
  {
    key: "encrypted",
    text: "multipart/encrypted",
    value: "multipart/encrypted",
  },
  { key: "plain", text: "text/plain", value: "text/plain" },
  { key: "vcard", text: "text/vcard", value: "text/vcard" },
];
// to sort the content types in alphabetical order
export const sortedContentTypes = content_Types.sort(function (first, second) {
  const valueFirst = first.value.toUpperCase();
  const valueSecond = second.value.toUpperCase();
  if (valueFirst < valueSecond) {
    return -1;
  } else if (valueFirst > valueSecond) {
    return 1;
  } else return 0;
});
