import React from "react";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Features from "./Features";
import "../../assets/css/Home/Intro.css";

export default function Intro() {
  return (
    <Box className="intro-root">
      <Box className="intro-root1">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className="intro-heading" variant="h3" align="center">
            Mystique
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          className="intro-grid"
        >
          <Grid item md={8} lg={8}>
            <Typography className="intro-text">
              With modern software practices, especially with SOA/Microservices
              architecture the application is built with several loosely coupled
              services. Development & Reliability Engineering teams that want to
              access these dependent services during development and testing
              often find its difficult to access due to firewall or its under
              development. With TDD & BDD practice, developers can continuously
              test the functionality of the application in CI-pipelines by
              spinning up a virtual service based on the integration service
              contract.
            </Typography>
          </Grid>
          <Grid item md={8} lg={8}>
            <Typography className="intro-text">
              Mystique is a managed / Software as a Service offering that let
              you spin up a virtual service on demand with a simple
              configuration.
            </Typography>
          </Grid>
          {/* <Grid item md={8} lg={8}>
            <FeatureBullets />
          </Grid> */}
        </Grid>
        <Features />
      </Box>
    </Box>
  );
}
