import React from "react";
import { Tab } from "semantic-ui-react";
import ParentPane from "./ParentPane";
import UsecasePane from "./UsecasePane";
import "../../src/assets/css/collapse.css";
import { useSelector } from "react-redux";

let config = require("../config/config");

// tab menu color
const color = "blue";

const TabContent = (props) => {
  // To fetch the hostname of current customer from store.
  const customer = useSelector((state) => state?.virtualizer?.customers);

  const hostName = [];
  let host = customer?.customer?.customHostName;
  if (host !== undefined && host.includes(config.domain)) {
    hostName.push(host.split(config.domain, 1));
  }
  const actionProps = {
    editStatus: props?.editStatus,
    setEditStatus: props?.setEditStatus,
    rowEditData: props?.rowEditData,
    dispatchHost: hostName.flat(),
  };
  const { activeIndex } = props?.activeIndex;

  return (
    <Tab
      menu={{
        fluid: true,
        secondary: true,
        pointing: true,
        color,
      }}
      panes={[
        {
          menuItem: "Parent",
          fontSize: 17,
          render: () => (
            <Tab.Pane attached={false}>
              {<ParentPane {...actionProps} />}
            </Tab.Pane>
          ),
        },
        {
          menuItem: "Child",
          fontSize: 17,
          render: () => {
            return (
              <Tab.Pane attached={false}>
                {<UsecasePane {...actionProps} />}
              </Tab.Pane>
            );
          },
        },
      ]}
      activeIndex={activeIndex}
      onTabChange={props.handleTabChange}
    />
  );
};

export default TabContent;
