import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import Dashboard from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store/store";

// /**
//  * Use combineReducers for multiple reducers
//  * @type {Reducer<CombinedState<unknown>>}
//  */
// const rootReducer = combineReducers({
//   virtualizer,
// });

// /**
//  * redux-thunk ~ middleware for handling requests asynchronously and resolve the promise that returns
//  * @type {Store<EmptyObject & S, AnyAction> & Store<S, A> & {dispatch: ThunkDispatch<{}, undefined, AnyAction>}}
//  */
// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Dashboard />
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById("root")
);
