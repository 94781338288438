import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
  Visibility,
  Card,
} from "semantic-ui-react";
import "../assets/css/home.css";
import icon1 from "../../src/assets/img/mystiqueicon1.png";
import icon2 from "../../src/assets/img/mystiqueicon2.png";
import icon3 from "../../src/assets/img/mystiqueicon3.png";
import icon4 from "../../src/assets/img/mystiqueicon4.png";
import logo from "../../src/assets/img/logo.png";
import { Link } from "react-router-dom";

const segStyle = {
  fontWeight: "bold",
  color: "#e5e9f2",
  wordBreak: "break-all",
  fontSize: "15px",
  paddingRight: "10px",
};

const labelStyle = {
  color: "rgb(3 153 170)",
  padding: "15px 0px 10px 0px",
  fontSize: "40px",
  textAlign: "center",
};

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container className="mainText" textAlign="left">
    <Header
      as="h1"
      content="Service Virtualization"
      inverted
      style={{
        textShadow: "2px 2px #000000",
        fontSize: mobile ? "2em" : "4em",
        fontWeight: "normal",
        marginBottom: 0,
        marginTop: mobile ? "1.5em" : "3em",
      }}
    />
    <Header
      as="h2"
      content="Simulate Mocked Responses for http Based API's/Services"
      inverted
      style={{
        textShadow: "2px 2px #000000",
        fontSize: mobile ? "1.5em" : "1.7em",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1.5em",
      }}
    />
    <Link to="/dashboard">
      <Button style={{ background: "rgb(3 153 170)" }} primary size="huge">
        Get Started
        <Icon name="right arrow" />
      </Button>
    </Link>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};
  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    return (
      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign="center"
            style={{ minHeight: 700, padding: "1em 0em" }}
            vertical
            className="mainSection"
          >
            <HomepageHeading />
          </Segment>
        </Visibility>
        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};

  render() {
    const { children } = this.props;
    return (
      <Media at="mobile">
        <Segment
          textAlign="center"
          style={{ minHeight: 350, padding: "1em 0em" }}
          vertical
        >
          <HomepageHeading mobile />
        </Segment>
        {children}
      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: "4em 0em" }} size="small">
      <Card
        fluid
        raised
        color="blue"
        style={{
          marginLeft: "30px",
          marginRight: "30px",
          width: "fit-content",
        }}
      >
        <Header style={labelStyle}>What is Mystique?</Header>
        <Card.Description
          style={{ padding: "10px 0px 10px 10px", width: "auto" }}
        >
          <p style={segStyle}>
            With modern software practices, especially with SOA/Microservices
            architecture the application is built with several loosely coupled
            services. Development & Reliability Engineering teams that want to
            access these dependent services during development and testing often
            find its difficult to access due to firewall or its under
            development. With TDD & BDD practice, developers can continuously
            test the functionality of the application in CI-pipelines by
            spinning up a virtual service based on the integration service
            contract.
          </p>
          <p style={segStyle}>
            Mystique is a managed / Software as a Service offering that let you
            spin up a virtual service on demand with a simple configuration.
          </p>
          <Segment.Inline style={segStyle}>
            <Icon name="bullseye" color="blue" />
            Control traffic distribution - throttling % of traffic to different
            versions of an app.
          </Segment.Inline>
          <Segment.Inline style={segStyle}>
            <Icon name="bullseye" color="blue" />
            Security/Access control with whitelisting/blacklisting ip address /
            API key
          </Segment.Inline>
          <Segment.Inline style={segStyle}>
            <Icon name="bullseye" color="blue" />
            Rate Limiting
          </Segment.Inline>
          <Segment.Inline style={segStyle}>
            <Icon name="bullseye" color="blue" />
            Metrics and Monitoring
          </Segment.Inline>
        </Card.Description>
      </Card>
    </Segment>

    <Segment style={{ padding: "4em 0em" }} size="large">
      <Header
        as="h1"
        style={{
          color: "rgb(3 153 170)",
          padding: "0px 0px 10px 0px",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        Features
      </Header>
      <Grid container divided="vertically">
        <Grid.Row>
          <Grid.Column>
            <Card fluid color="blue" className="featureStyle">
              <Image src={icon1} floated="right" size="tiny" />
              <Card.Content>
                <Card.Header>Easy On board</Card.Header>
                <Card.Description style={segStyle}>
                  Easily onboard a virtual service by adding an entry in the
                  control plane with necessary canned http request & response.
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid container divided="vertically">
        <Grid.Row>
          <Grid.Column>
            <Card fluid raised color="blue" className="featureStyle">
              <Image src={icon3} floated="right" size="tiny" />
              <Card.Content>
                <Card.Header>Load test</Card.Header>
                <Card.Description style={segStyle}>
                  Load test any service to establish SLO without worrying about
                  dependent services SLAs. Cloud native autoscaling support for
                  the virtual app.
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid container divided="vertically">
        <Grid.Row>
          <Grid.Column>
            <Card fluid raised color="blue" className="featureStyle">
              <Image src={icon2} floated="right" size="tiny" />
              <Card.Content>
                <Card.Header>Robust Request Matchers</Card.Header>
                <Card.Description style={segStyle}>
                  Robust request matching patterns, headers and cookies to route
                  the request to appropriate stubs. A single request path can
                  have multiple scenarios.
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid container divided="vertically">
        <Grid.Row>
          <Grid.Column>
            <Card fluid raised color="blue" className="featureStyle">
              <Image src={icon4} floated="right" size="tiny" />
              <Card.Content>
                <Card.Header>Mock or Route</Card.Header>
                <Card.Description style={segStyle}>
                  Mock only, Route only or hybrid - Incase of failure response
                  from dependent services, respond with mock.
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header
                inverted
                as="h4"
                content="Quick Links"
                style={{ color: "#5c9eec" }}
              />
              <List link inverted>
                <List.Item href="http://mystiquex.com/products.html" as="a">
                  Our Products
                </List.Item>
                <List.Item href="http://mystiquex.com/documents.html" as="a">
                  Mystique
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header
                inverted
                as="h4"
                content="Get In Touch"
                style={{ color: "#5c9eec" }}
              />
              <List inverted link>
                <List.Item as="a">
                  4575 Webb Bridge Rd, #2782 Alpharetta, GA 30005-9998, <br />
                  +1 (786) 518-6958, <br />
                  support@mystiquex.com
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Image
                size="small"
                src={logo}
                href="https://mystiquex.com/index.html"
                target="_blank"
              />
              <br />
              <Icon.Group>
                <Icon
                  link
                  name="linkedin"
                  size="large"
                  // href="https://www.linkedin.com/company/mystiquex"
                />
                <Icon
                  link
                  name="youtube"
                  size="large"
                  style={{ marginLeft: "30px" }}
                />
              </Icon.Group>
              <List inverted link>
                <List.Item as="a">
                  Copyright © {new Date().getFullYear()} Mystique InfoTech
                  Private Limited
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default HomepageLayout;
