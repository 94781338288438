import React, { useState } from "react";
import { Form, Button } from "semantic-ui-react";
import "../../src/assets/css/addMock.css";
import { useSelector, useDispatch } from "react-redux";
import { ParentOrChildMock } from "../store/actions/actions";
import consola from "consola";
import { useSnackbar } from "notistack";

const ParentPane = (props) => {
  const dispatch = useDispatch();
  const [appName, setAppName] = useState("");
  const [pattern, setPattern] = useState("");
  const [desc, setDesc] = useState("");
  const user = useSelector((state) => state?.virtualizer?.currentUser);
  const email = user.email;
  const style = {
    labelStyle: {
      fontWeight: "bold",
      color: "#ffff",
      fontSize: 15,
    },
  };
  const { enqueueSnackbar } = useSnackbar();

  // to set the states for the parent dialog box
  const handleCloseParent = (event) => {
    return props?.handleCloseParent(event);
  };
  // to make edit status false
  const handleEditClose = (event) => {
    return props?.handleEditClose(event);
  };

  /**
   * create & update parent handler
   * @param event
   * @param type
   * @returns {Promise<void>}
   */
  const saveOrUpdateMock = async (event, type) => {
    event.preventDefault();
    const parentModel = {
      emailId: email,
      routeManager: [
        {
          appName: type === "UPDATE" ? props?.rowEditData?.appName : appName,
          description: desc,
          pattern: type === "UPDATE" ? props?.rowEditData?.pattern : pattern,
          type: "MOCK",
          hierarchy: "parent",
        },
      ],
    };
    if (Array.isArray(props?.dispatchHost) && props?.dispatchHost.length > 0) {
      switch (type) {
        case "CREATE":
          await dispatch(
            ParentOrChildMock(parentModel, props?.dispatchHost[0], type)
          );
          handleCloseParent(event);
          enqueueSnackbar("Parent Mock Created Successfully", {
            variant: "success",
          });

          break;
        case "UPDATE":
          await dispatch(
            ParentOrChildMock(parentModel, props?.dispatchHost[0], type)
          );
          handleCloseParent(event);
          handleEditClose(event);
          enqueueSnackbar("Parent Mock Updated Successfully", {
            variant: "success",
          });
          break;
        default:
          consola.error("Action type is not defined for the dispatch.");
      }
    }
  };

  /**
   * Template to update or view parent
   * @returns {JSX.Element}
   */
  const updateOrViewParentTemplate = () => {
    if (!props?.openView) {
      return (
        <div>
          <Form.Input
            fluid
            id="app"
            name="appName"
            label="Application Name"
            value={props?.rowEditData?.appName}
            onChange={(event) => setAppName(event.target.value)}
          />
          <Form.Input
            fluid
            id="path"
            name="pattern"
            label="Request Path"
            placeholder="eg: /home"
            value={props?.rowEditData?.pattern}
            onChange={(event) => setPattern(event.target.value)}
          />
          <Form.Input
            fluid
            path="des"
            name="description"
            label="Description"
            defaultValue={props?.rowEditData?.description}
            onChange={(event) => setDesc(event.target.value)}
          />
          <Button
            className="submitButton"
            onClick={(event) => saveOrUpdateMock(event, "UPDATE")}
            style={style.labelStyle}
          >
            Save
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ cursor: "none", pointerEvents: "none" }}>
          <Form.Input
            fluid
            id="app"
            name="appName"
            label="Application Name"
            value={props?.rowEditData?.appName}
            readOnly="appName"
          />
          <Form.Input
            fluid
            id="path"
            name="pattern"
            label="Request Path"
            placeholder="eg: /home"
            value={props?.rowEditData?.pattern}
            readOnly="pattern"
          />
          <Form.Input
            fluid
            path="des"
            name="description"
            label="Description"
            value={props?.rowEditData?.description}
            readOnly="description"
          />
        </div>
      );
    }
  };

  /**
   * Template to create parent
   * @returns {JSX.Element}
   */
  const createParentTemplate = () => {
    return (
      <div>
        <Form.Input
          fluid
          id="app"
          name="appName"
          label="Application Name"
          onChange={(event) => setAppName(event.target.value)}
        />
        <Form.Input
          fluid
          id="path"
          name="pattern"
          label="Request Path"
          placeholder="eg: /home"
          onChange={(event) => setPattern(event.target.value)}
        />
        <Form.Input
          fluid
          path="des"
          name="description"
          label="Description"
          onChange={(event) => setDesc(event.target.value)}
        />
        <Button
          className="submitButton"
          onClick={(event) => saveOrUpdateMock(event, "CREATE")}
          style={style.labelStyle}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <Form size="large" inverted className="parentStyle">
      {props?.editStatus && props?.editParent
        ? updateOrViewParentTemplate()
        : createParentTemplate()}
    </Form>
  );
};

export default ParentPane;
