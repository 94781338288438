import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Link,
  MenuItem,
} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import MystiqueLogo from "../../src/assets/img/logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import UserLogoutButton from "../security/googleIdentity/UserLogoutButton";
import { useSelector } from "react-redux";
import UserLoginButton from "../security/googleIdentity/UserLoginButton";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 10,
  },
  header: {
    background:
      "linear-gradient(0deg, rgba(0,27,75,1) 0%, rgba(0,9,53,1) 40%, rgba(0,9,53,1) 60%, rgba(0,27,75,1) 100%)",
    paddingRight: "79px",
    paddingLeft: "118px",
    boxShadow: "0 8px 40px 0 rgba(0,0,0,1)",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    width: 150,
    height: 34,
  },
  text: {
    color: "#ffffff",
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
    fontWeight: 700,
    "&:hover": { color: "#f50d57" },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuButton: {
    textDecoration: "none",
    padding: 20,
  },
  mobileMenu: {
    borderRadius: 15,
    background:
      "linear-gradient(0deg, rgba(0,27,75,.8) 0%, rgba(0,9,53,.8) 40%, rgba(0,9,53,.8) 60%, rgba(0,27,75,.8) 100%)",
    boxShadow: "0 8px 40px 0 rgba(0,0,0,1)",
  },
}));

export default function NavBar() {
  const styles = useStyles();

  const user = useSelector((state) => state?.virtualizer?.currentUser);
  const userData = user?.email;

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={styles.toolbar}>
        {mystiquexLogo}
        <div>{getDrawerChoices()}</div>
      </Toolbar>
    );
  };

  const MobileMenu = () => {
    const styles = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === "Tab") {
        event.preventDefault();
        setOpen(false);
      }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);

    return (
      <div className={styles.root}>
        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            onClick={handleToggle}
          >
            <MenuIcon fontSize="large" style={{ color: "#5c9eec" }} />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper className={styles.mobileMenu}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleClose}>
                        <Button
                          component={RouterLink}
                          to="/"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography className={styles.text}>Home</Typography>
                        </Button>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Button
                          component={RouterLink}
                          to="/dashboard"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography className={styles.text}>
                            Dashboard
                          </Typography>
                        </Button>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Button
                          component={RouterLink}
                          to="/home"
                          style={{ textDecoration: "none" }}
                        >
                          {userData ? (
                            <UserLogoutButton />
                          ) : (
                            <UserLoginButton />
                          )}
                        </Button>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  };

  const displayMobile = () => {
    return (
      <Toolbar>
        <IconButton>
          <MobileMenu />
        </IconButton>
        <div>{mystiquexLogo}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return (
      <div>
        <Button component={RouterLink} to="/" className={styles.menuButton}>
          <Typography className={styles.text}>Home</Typography>
        </Button>
        <Button
          component={RouterLink}
          to="/dashboard"
          className={styles.menuButton}
        >
          <Typography className={styles.text}>Dashboard</Typography>
        </Button>
        <Button component={RouterLink} to="/home" className={styles.menuButton}>
          {userData ? <UserLogoutButton /> : <UserLoginButton />}
        </Button>
      </div>
    );
  };

  const mystiquexLogo = (
    <Link href="https://mystiquex.com/index.html" target="_blank">
      <CardMedia
        component="img"
        alt={"MystiqueX - Service Virtualization"}
        src={MystiqueLogo}
        className={styles.logo}
      />
    </Link>
  );

  return (
    <AppBar position="static" className={styles.header}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
}
