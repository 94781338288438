import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import "../../../src/assets/css/loginPage-signupPage.css";
import {
  createUserAccount,
  googleSignUpAction,
} from "../../store/actions/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import GoogleButton from "react-google-button";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Popup } from "semantic-ui-react";
import GIPErrorMessage from "../../config/GIPErrorMessage";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8),
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor:
      "linear-gradient(225deg, rgb(0, 27, 75) 0%, rgb(0, 9, 53) 100%)",
    color: "#ffff",
    borderRadius: 15,
    boxShadow: "0 8px 40px 0 rgba(0,0,0,1)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorAlert: {
    color: "#FF0000",
  },
  displayText: {
    fontFamily: "Arial, Helvetica, sans-serif",
    fontWeight: "700",
  },
  footerText: {
    fontFamily: "Arial, Helvetica, sans-serif",
    fontWeight: "700",
    color: "#FFFFFF",
  },
}));

function UserSignUp() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [isActionLoading, setIsActionLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUserSignUp = async (data) => {
    setIsActionLoading(true);
    const result = await dispatch(createUserAccount(data.email, data.password));
    if (result?.code) {
      const errorMessage = GIPErrorMessage(result?.code);
      enqueueSnackbar(errorMessage, {
        variant: "Error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      // if there is no error, then we redirect the user to the home page
      history.push("/");
    }
    setIsActionLoading(false);
    return false;
  };

  const handleGoogleSignUp = async () => {
    const googleSignUpresult = await dispatch(googleSignUpAction());
    if (googleSignUpresult?.code) {
      // if there is error, we will display as notification
      const errorMessage = GIPErrorMessage(googleSignUpresult?.code);
      if (errorMessage) {
        enqueueSnackbar(googleSignUpresult?.message, {
          variant: "Error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      // if there is no error, then we redirect the user to the home page
      history.push("/");
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          className="MuiCardMedia-root makeStyles-logo-3 MuiCardMedia-media MuiCardMedia-img"
          src="/static/media/logo.7bb04b0b.png"
          alt="MystiqueX - Service Virtualization"
        ></img>
        <br />
        <Typography component="h1" variant="h5" className={classes.displayText}>
          Sign Up
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(handleUserSignUp)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <br />
              <GoogleButton
                type="dark"
                label="Sign Up With Google"
                style={{
                  width: "383px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontWeight: "700",
                }}
                className={classes.googleSignUpButton}
                name="googleSignUpButton"
                onClick={() => handleGoogleSignUp()}
              />
              <br />
              <p
                className={classes.displayText}
                style={{ textAlign: "center" }}
              >
                Or
              </p>
              <h5 className={classes.displayText}>Email Address</h5>
              <TextField
                style={{
                  background: "#001B4B",
                }}
                variant="outlined"
                fullWidth
                name="email"
                autoComplete="email"
                color="primary"
                className="inputFields"
                {...register("email", {
                  required: true,
                  pattern:
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                required
              />
              {errors.email && (
                <p className={classes.errorAlert}>
                  Please enter valid email address
                </p>
              )}
            </Grid>
            <Grid item xs={12}>
              <h5 className={classes.displayText}>Password</h5>
              <TextField
                style={{
                  background: "#001B4B",
                }}
                variant="outlined"
                fullWidth
                name="password"
                type="password"
                autoComplete="current-password"
                color="primary"
                className="inputFields"
                {...register("password", {
                  required: true,
                  pattern: /^(?=.*\d)(?=.*[a-z]).{6,}$/,
                  minLength: 6,
                })}
                required
              />
              {errors.password && (
                <p className={classes.errorAlert}>
                  Password should be minimum 6 character and include alphabet
                  letters (a-z, A-Z) and numbers (0-9)
                </p>
              )}
            </Grid>
          </Grid>
          <Button
            style={{
              background:
                "linear-gradient(90deg,#5c9eec -100%,#001b4b 112%,#000935 0)",
            }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isActionLoading}
          >
            <Typography className={classes.displayText}> Sign Up </Typography>
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/signin">
                <Typography className={classes.footerText}>
                  Already have an account? Sign In
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
export default UserSignUp;
