import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { UserSignOutAction } from "../../store/actions/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  button: {
    borderRadius: 10,
    background:
      "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
  },
  text: {
    color: "#ffffff",
    textShadow: "2px 2px #000000",
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
    fontWeight: "bold",
    "&:hover": {
      color: "#f50d57",
    },
  },
});

const UserLogoutButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleUserSignOut = async () => {
    const result = await dispatch(UserSignOutAction());
    history.push("/");
  };
  const styles = useStyles();
  return (
    <Button
      onClick={() => handleUserSignOut()}
      size="large"
      className={styles.button}
      variant="contained"
    >
      <Typography className={styles.text}>Log Out</Typography>
    </Button>
  );
};

export default UserLogoutButton;
