import React from "react";
import { Popup, Icon } from "semantic-ui-react";

const iconStyle = {
  color: "rgb(3 153 170)",
  position: "absolute",
  left: "85px",
  marginTop: "4px",
};

const Info = () => (
  <Popup
    className="hybridInfo"
    content=" Configure Hybrid-Rule for eg: if resp_code is not equal(!=) 200 from the actual destination, then return the configured the mock response. Else route to the destination. ** Url is mandatory. "
    trigger={<Icon name="exclamation circle" size="small" style={iconStyle} />}
  />
);

export default Info;
