/**
 * @author: akhil_suseelan
 * Service class wrapper for Axios
 */
import axios from "axios";
import qs from "qs";
import consola from "consola";
import { successHandler, errorHandler } from "./responseHandler";

class httpService {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(successHandler, errorHandler);
    this.service = service;
  }

  headers() {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  // GET METHOD
  get(path, callback) {
    try {
      return this.service.get(path, this.headers()).then((response) => {
        return callback(response);
      });
    } catch (e) {
      consola.error("axios error: GET", e);
    }
  }

  // GET METHOD
  getByParam(path, param, callback) {
    try {
      return this.service
        .request({
          method: "GET",
          url: path,
          params: param,
          paramsSerializer: (params) => qs.stringify(params, { encode: false }),
          ...this.headers(),
        })
        .then((response) => {
          return callback(response);
        });
    } catch (e) {
      consola.error("axios error: GET", e);
    }
  }

  // POST METHOD
  post(path, payload, callback) {
    try {
      return this.service
        .request({
          method: "POST",
          url: path,
          data: payload,
          ...this.headers(),
        })
        .then((response) => {
          return callback(response);
        });
    } catch (e) {
      consola.error("axios error: POST", e);
    }
  }

  // PUT METHOD
  put(path, payload, callback) {
    try {
      return this.service
        .request({
          method: "PUT",
          url: path,
          data: payload,
          ...this.headers(),
        })
        .then((response) => {
          return callback(response);
        });
    } catch (e) {
      consola.error("axios error: PUT", e);
    }
  }

  // DELETE METHOD
  delete(path, payload, callback) {
    try {
      return this.service
        .request({
          method: "DELETE",
          url: path,
          data: payload,
          ...this.headers(),
        })
        .then((response) => {
          return callback(response);
        });
    } catch (e) {
      consola.error("axios error: DELETE", e);
    }
  }
}

export default new httpService();
