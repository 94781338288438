/**
 * Reducers that takes the action AND previous state and return the new state.
 */
import * as types from "../constants/actionTypes";

const initalState = {
  customers: [],
  mocks: [],
  error: "",
  currentUser: [],
};

// virtualizer ~ random name set for reducer
export const virtualizer = (state = initalState, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_BY_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        customers: action.payload,
      });
    case types.GET_CUSTOMER_BY_EMAIL_FAILURE:
      return Object.assign({}, state, {
        customers: [],
        error: action.payload,
      });
    case types.GET_ALL_CUSTOMER_MOCKS:
      return Object.assign({}, state, {
        mocks: action.payload,
      });
    case types.CURRENT_USER_DETAILS:
      return Object.assign({}, state, {
        currentUser: action.payload,
      });
    case types.RESET_ALL_STORE_DATA:
      return Object.assign({}, state, {
        currentUser: null,
        customers: [],
        mocks: [],
        error: null,
      });
    default:
      return state;
  }
};

export default virtualizer;
