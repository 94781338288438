import React from "react";
import { Box } from "@material-ui/core";
import Banner from "./home/Banner";
import Intro from "./home/Intro";

export default function HomePageNew() {
  return (
    <Box>
      <Banner />
      <Intro />
    </Box>
  );
}
