import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import InputIcon from "@material-ui/icons/Input";
import YoutubeBackground from "react-youtube-background";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "80vh",
    boxShadow: "0 8px 40px -5px rgba(0,0,0,1)",
  },
  banner: {
    padding: "0, 40, 0, 40",
    alignContent: "center",
  },
  heading: {
    textAlign: "left",
    paddingBottom: 10,
    color: "#ffffff",
    textShadow: "2px 2px #5c9eec",
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
  },
  subHeading: {
    textAlign: "left",
    paddingBottom: 10,
    color: "#ffffff",
    textShadow: "2px 2px #000000",
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
  },
  button: {
    borderRadius: 10,
    background:
      "linear-gradient(90deg,#5c9eec -100%, #001b4b 112%,#000935 100%)",
    "&:hover": {
      background:
        "linear-gradient(45deg, rgba(92,158,236,1) 0%, rgba(245,13,87,1) 25%, rgba(181,12,78,1) 45%, rgba(0,9,53,1) 100%)",
    },
  },
  buttonText: {
    color: "#ffffff",
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
    fontWeight: "bold",
    textShadow: "2px 2px #000000",
    marginLeft: 10,
  },
});

export default function Banner() {
  const styles = useStyles();

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <YoutubeBackground
      videoId={"pw2erZwO8qA"}
      aspectRatio={"16:9"}
      overlay={"rgba(0,0,0,.8)"}
      nocookie={false}
    >
      <Box className={styles.root}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems={mobileView ? "flex-start" : "center"}
          className={styles.banner}
          spacing={2}
        >
          <Grid item>
            <Typography
              variant={mobileView ? "h4" : "h2"}
              className={styles.heading}
            >
              Service Virtualization
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={mobileView ? null : "h5"}
              className={styles.subHeading}
            >
              Simulate Mocked Responses for http Based APIs/Services
            </Typography>
          </Grid>
          <Grid item>
            <Link to="/dashboard">
              <Button
                className={styles.button}
                style={{ padding: mobileView ? 8 : 15 }}
                variant="contained"
              >
                <Typography className={styles.buttonText}>
                  Get Started
                </Typography>
                <InputIcon className={styles.buttonText} fontSize="medium" />
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </YoutubeBackground>
  );
}
