import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Loading } from "../../components";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...args }) => {
  const user = useSelector((state) => state?.virtualizer?.currentUser);
  const userData = user?.email;

  return (
    <Route
      {...args}
      render={(props) =>
        userData ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
};

export default PrivateRoute;
