import React, { forwardRef } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Container } from "semantic-ui-react";
import "../../src/assets/css/collapse.css";
import TabContent from "./TabContent";

const addMockStyle = {
  fontWeight: "bold",
};

const AddMock = forwardRef((props, ref) => {
  const handleClickToggle = (eventKey) => {
    if (eventKey === props?.activeKey) {
      props.setActiveKey("");
    } else {
      props.setActiveKey(eventKey);
    }
  };
  return (
    <div className="row" ref={ref}>
      <Accordion style={{ paddingLeft: "32px" }} activeKey={props?.activeKey}>
        <Card className="collapseStyle">
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              style={addMockStyle}
              variant="link"
              eventKey="0"
              onClick={() => handleClickToggle("0")}
            >
              Add Mock
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Container className="mockbody">
                <TabContent
                  activeIndex={props?.activeIndex}
                  setActiveIndex={props?.setActiveIndex}
                  handleTabchange={props?.handleTabChange}
                  editStatus={props?.editStatus}
                  setEditstatus={props?.setEditStatus}
                  rowEditData={props?.rowEditData}
                />
              </Container>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
});

export default AddMock;
