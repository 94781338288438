import React from "react";
import {
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import "../assets/css/home.css";
import logo from "../assets/img/logo.png";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    position: "static",
    bottom: 0,
  },
});

function Footer() {
  const styles = useStyles();
  return (
    <Segment
      inverted
      vertical
      style={{ padding: "5em 0em", backgroundColor: "black" }}
      className={styles.root}
    >
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}></Grid.Column>
            <Grid.Column width={3}>
              <Header
                inverted
                as="h4"
                content="Quick Links"
                style={{ color: "#5c9eec" }}
              />
              <List link inverted>
                <List.Item href="http://mystiquex.com/products.html" as="a">
                  Our Products
                </List.Item>
                <List.Item href="http://mystiquex.com/documents.html" as="a">
                  Mystique
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header
                inverted
                as="h4"
                content="Get In Touch"
                style={{ color: "#5c9eec" }}
              />
              <List inverted link>
                <List.Item as="a">
                  4575 Webb Bridge Rd, #2782 Alpharetta, GA 30005-9998, <br />
                  +1 (786) 518-6958, <br />
                  support@mystiquex.com
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Image
                size="small"
                src={logo}
                href="https://mystiquex.com/index.html"
                target="_blank"
              />
              <br />
              <Icon.Group>
                <Icon
                  link
                  name="linkedin"
                  size="large"
                  // href="https://www.linkedin.com/company/mystiquex"
                />
                <Icon
                  link
                  name="youtube"
                  size="large"
                  style={{ marginLeft: "30px" }}
                />
              </Icon.Group>
              <List inverted link>
                <List.Item as="a">
                  Copyright © {new Date().getFullYear()} Mystique InfoTech
                  Private Limited
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}

export default Footer;
