import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles({
  card: {
    padding: 10,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "rgba(92, 158, 236, .3)",
    background:
      "radial-gradient(circle, rgba(0, 9, 53, .8) 40%, rgba(0,27,75, .8) 75%, rgba(0,0,0,0) 100%)",
    color: "#ffffff",
    height: 260,
    width: 300,
    alignItems: "center",
    boxShadow: "0 8px 40px -5px rgba(0,0,0,1)",
    transition: "transform 0.15s ease-in-out",
    "&:hover": {
      transform: "scale3d(1.05, 1.05, 1)",
      boxShadow: "0 16px 50px -5.5px rgba(0,0,0,1)",
    },
  },
  icons: {
    height: 50,
    width: 50,
  },
  cardTitle: {
    color: "#f50d57",
    textShadow: "2px 2px #000000",
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
  },
  cardText: {
    fontFamily: ["Arial", "Helvetica", "sans-serif"],
    textAlign: "justify",
    fontWeight: 600,
    textShadow: "2px 2px #000000",
    wordSpacing: -2,
    fontSize: 14,
  },
});

export default function FeatureCard({ feature }) {
  const styles = useStyles();
  return (
    <Card className={styles.card} variant="outlined">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <CardHeader className={styles.cardTitle} title={feature.title} />
        </Grid>
        <Grid item>
          <CardMedia image={feature.image} className={styles.icons} />
        </Grid>
        <Grid item>
          <CardContent>
            <Typography className={styles.cardText}>
              {feature.description}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
