import React from "react";
import { SemipolarLoading } from "react-loadingg";
import "../assets/css/loading.css";

const AppLoadingBar = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      zIndex: 100,
      height: "100vh",
      width: "100vw",
      background:
        "radial-gradient(circle, rgba(0,9,53,1) 20%, rgba(0,27,75,1) 100%)",
    }}
  >
    <SemipolarLoading size="large" />
  </div>
);

export default AppLoadingBar;
