import React from "react";
import MainContent from "../components/MainContent";

const DashBoard = () => (
  <div className="panel panel-default">
    <MainContent />
  </div>
);

export default DashBoard;
