import mystiqueicon1 from "../assets/img/mystiqueicon1.png";
import mystiqueicon2 from "../assets/img/mystiqueicon2.png";
import mystiqueicon3 from "../assets/img/mystiqueicon3.png";
import mystiqueicon4 from "../assets/img/mystiqueicon4.png";

export const App_URL = {
  baseURL: "https://api.mystiquex.com/route-manager/v1/",
  createCustomer: "https://api.mystiquex.com/route-manager/v1/customer/create",
  getCustomerByEmailId:
    "https://api.mystiquex.com/route-manager/v1/customer/getCustomerByemailId",
  updateMock: "https://api.mystiquex.com/route-manager/v1/route/update",
  createMock: "https://api.mystiquex.com/route-manager/v1/route/create",
  getAllMock: "https://api.mystiquex.com/route-manager/v1/route/getAllRoutes",
};

export const domain = ".mystiquex.com";

export const requestMethods = [
  { key: "", text: "", value: "" },
  { key: "get", text: "GET", value: "GET" },
  { key: "post", text: "POST", value: "POST" },
  { key: "put", text: "PUT", value: "PUT" },
  { key: "patch", text: "PATCH", value: "PATCH" },
  { key: "delete", text: "DELETE", value: "DELETE" },
  { key: "options", text: "OPTIONS", value: "OPTIONS" },
];

export const header = [
  { key: "", text: "", value: "" },
  { key: "cookie", text: "Cookie", value: "Cookie" },
  { key: "header", text: "Header", value: "Header" },
];

export const hybridSelect = [
  { key: "", text: "", value: "" },
  { key: "equalTo", text: "==", value: "==" },
  { key: "notEqualTo", text: "!=", value: "!=" },
];

export const feature = [
  {
    featureBulletin: [
      {
        id: 1,
        feature:
          "Control traffic distribution - throttling % of traffic to different versions of an app.",
      },
      {
        id: 2,
        feature:
          "Security/Access control with whitelisting/blacklisting ip address / API key.",
      },
      {
        id: 3,
        feature: "Rate Limiting.",
      },
      {
        id: 4,
        feature: "Metrics and Monitoring.",
      },
    ],
  },
  {
    featureCard: [
      {
        id: 1,
        title: "Easy On board",
        image: mystiqueicon1,
        description:
          "Easily onboard a virtual service by adding an entry in the control plane with necessary canned http request & response.",
      },
      {
        id: 2,
        title: "Load test",
        image: mystiqueicon2,
        description:
          "Load test any service to establish SLO without worrying about dependent services SLAs. Cloud native autoscaling support for the virtual app.",
      },
      {
        id: 3,
        title: "Robust Request Matchers",
        image: mystiqueicon3,
        description:
          "Robust request matching patterns, headers and cookies to route the request to appropriate stubs. A single request path can have multiple scenarios.",
      },
      {
        id: 4,
        title: "Mock or Route",
        image: mystiqueicon4,
        description:
          "Mock only, Route only or hybrid - Incase of failure response from dependent services, respond with mock.",
      },
    ],
  },
];
