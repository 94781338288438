/**
 * @author ~ akhil_suseelan
 * All actions carries payload and sent to Redux store
 */
import * as types from "../constants/actionTypes";
import * as API from "../../services/serviceHandler";
import { auth, provider } from "../../config/firebaseInit";
import consola from "consola";

const URL = require("../../config/config");

// fetch customer details by email
export const fetchCustomerByEmailId = (email) => {
  return async (dispatch) => {
    const param = { emailId: email };
    const result = await API.getCustomerByEmailId(
      URL.App_URL.getCustomerByEmailId,
      param
    );
    if (result && result.status === 200 && result.data) {
      dispatch({
        type: types.GET_CUSTOMER_BY_EMAIL_SUCCESS,
        payload: result.data,
        status: result.status,
      });
    } else if (
      result.status === 401 ||
      result.status === 404 ||
      result.status === 400 ||
      result.status === 500
    ) {
      consola.error(
        "Error occurred during fetchCustomerByEmailId",
        result.status
      );
    }
  };
};

// Get customer based on the hostname and type
export const fetchAllCustomerMocks = (hostName) => {
  return async (dispatch) => {
    const param = { customHostName: hostName, type: "mock" };
    const result = await API.getAllMocks(URL.App_URL.getAllMock, param);
    if (result && result.status === 200 && result.data) {
      dispatch({
        type: types.GET_ALL_CUSTOMER_MOCKS,
        payload: result.data,
        status: result.status,
      });
    } else if (
      result.status === 401 ||
      result.status === 404 ||
      result.status === 400 ||
      result.status === 500
    ) {
      consola.error("Error occurred during fetchAllCustomer", result.status);
    }
  };
};

// create a new customer
export const createCustomer = (payload, email) => {
  return async (dispatch) => {
    const result = await API.createCustomer(
      URL.App_URL.createCustomer,
      payload
    );
    if (result && result.status === 201 && result.data) {
      if (result.status === 201) {
        dispatch(fetchCustomerByEmailId(email));
      }
      return result.data;
    } else if (
      result.status === 401 ||
      result.status === 404 ||
      result.status === 400 ||
      result.status === 500
    ) {
      consola.error("Error occurred during createCustomer", result.status);
    }
  };
};

export const ParentOrChildMock = (payload, hostName, type) => {
  return async (dispatch) => {
    if (type === "CREATE") {
      const result = await API.createMock(URL.App_URL.createMock, payload);
      if (result.data) {
        if (result.status === 201 && hostName !== undefined) {
          dispatch(fetchAllCustomerMocks(hostName));
        }
        return result.data;
      } else if (
        result.status === 401 ||
        result.status === 404 ||
        result.status === 400 ||
        result.status === 500
      ) {
        consola.error("Error occurred during ParentOrChildMock", result.status);
      }
    }
    if (type === "UPDATE") {
      const updateResult = await API.updateMock(
        URL.App_URL.updateMock,
        payload
      );
      if (updateResult.data) {
        if (updateResult.status === 201 && hostName !== undefined) {
          dispatch(fetchAllCustomerMocks(hostName));
        }
        return updateResult.data;
      } else if (
        updateResult.status === 401 ||
        updateResult.status === 404 ||
        updateResult.status === 400 ||
        updateResult.status === 500
      ) {
        consola.error(
          "Error occurred during ParentOrChildMock",
          updateResult.status
        );
      }
    }
  };
};

export const deleteMock = (payload, hostName) => {
  return async (dispatch) => {
    const result = await API.deleteMock(URL.App_URL.updateMock, payload);
    if (result && result.status === 201 && result.data) {
      if (hostName !== undefined) {
        dispatch(fetchAllCustomerMocks(hostName));
      }
    } else if (
      result.status === 401 ||
      result.status === 404 ||
      result.status === 400 ||
      result.status === 500
    ) {
      consola.error("Error occurred during deleteMock", result.status);
    }
  };
};

// user signup flow
export const createUserAccount = (email, password) => {
  return async (dispatch) => {
    const result = await auth
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        consola.log("Signup response", response);
        return response.user;
      })
      .catch((error) => {
        consola.log("Error:", error?.code);
        return error;
      });
    // check if error code is present inside the result, if not we dispatch user data into store
    if (!result?.code) {
      dispatch({
        type: types.CURRENT_USER_DETAILS,
        payload: result,
        status: null,
      });
    }
    return result;
  };
};

// user signup flow
export const UserSignInAction = (email, password) => {
  return async (dispatch) => {
    const result = await auth
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        consola.log("SignIn response", response);
        return response.user;
      })
      .catch((error) => {
        consola.log("Error:", error?.code);
        return error;
      });
    // check if error code is present inside the result, if not we dispatch user data into store
    if (!result?.code) {
      dispatch({
        type: types.CURRENT_USER_DETAILS,
        payload: result,
        status: null,
      });
    }
    return result;
  };
};

// user signin using google
export const googleSignInAction = () => {
  return async (dispatch) => {
    const result = await auth
      .signInWithPopup(provider)
      .then((response) => {
        return response.user;
      })
      .catch((error) => {
        consola.log("Error:", error?.code);
        return error;
      });
    // check if error code is present inside the result, if not we dispatch user data into store
    if (!result?.code) {
      dispatch({
        type: types.CURRENT_USER_DETAILS,
        payload: result,
        status: null,
      });
    }
    return result;
  };
};

export const googleSignUpAction = () => {
  return async (dispatch) => {
    const result = await auth
      .signInWithPopup(provider)
      .then((response) => {
        return response.user;
      })
      .catch((error) => {
        consola.log("Error:", error?.code);
        return error;
      });
    // check if error code is present inside the result, if not we dispatch user data into store
    if (!result?.code) {
      dispatch({
        type: types.CURRENT_USER_DETAILS,
        payload: result,
        status: null,
      });
    }
    return result;
  };
};

// user signout action
export const UserSignOutAction = () => {
  return async (dispatch) => {
    const result = await auth
      .signOut()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        consola.log("Error:", error?.code);
        return error;
      });
    if (!result?.code) {
      dispatch({
        type: types.RESET_ALL_STORE_DATA,
        payload: null,
        status: null,
      });
    }
    return result;
  };
};
