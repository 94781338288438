/**
 * Service handler for all HTTP calls
 */
import httpService from "./httpService";

export const getCustomerByEmailId = (url, param) => {
  return httpService.getByParam(url, param, (res) => {
    return res;
  });
};

export const getAllMocks = (url, param) => {
  return httpService.getByParam(url, param, (res) => {
    return res;
  });
};

export const createCustomer = (url, payload) => {
  return httpService.post(url, payload, (res) => {
    return res;
  });
};

export const createMock = (url, payload) => {
  return httpService.post(url, payload, (res) => {
    return res;
  });
};

export const deleteMock = (url, payload) => {
  return httpService.delete(url, payload, (res) => {
    return res;
  });
};

export const updateMock = (url, payload) => {
  return httpService.put(url, payload, (res) => {
    return res;
  });
};
